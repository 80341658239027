import React, { useEffect, useState } from "react";
import styles from "./rental.module.css";
import { ComponentSwiper } from "../ComponentSwiper";
import img1 from "../../../assets/images/image4-1.jpg";
import img2 from "../../../assets/images/image4-2.jpg";
import img3 from "../../../assets/images/image4-3.jpg";
import img4 from "../../../assets/images/image4-4.jpg";
import { useTranslation } from "react-i18next";
import { activePage } from "../../../App";

export function Rental() {
  const { t } = useTranslation();
  const [activeImg, setActiveImg] = useState(img1);
  const [img1Src, setImg1Src] = useState(img4);
  const [img2Src, setImg2Src] = useState(img2);
  const [img3Src, setImg3Src] = useState(img3);
  useEffect(() => {
    activePage("rental");
  }, []);

  const handleClick = (el) => {
    switch (el.currentTarget.id) {
      case "img1":
        setImg1Src(activeImg);
        setActiveImg(img1Src);
        break;
      case "img2":
        setImg2Src(activeImg);
        setActiveImg(img2Src);
        break;
      case "img3":
        setImg3Src(activeImg);
        setActiveImg(img3Src);
        break;

      default:
        setActiveImg(img4);
        setImg1Src(img1);
        setImg2Src(img2);
        setImg3Src(img3);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.galleryContainer}>
        <div className={styles.gallery}>
          <div className={styles.imageCont}>
            <img
              id={"img1"}
              onClick={(el) => handleClick(el)}
              className={styles.image}
              src={img1Src}
              alt={""}
            />
          </div>
          <div className={styles.imageCont}>
            <img
              id={"img2"}
              onClick={(el) => handleClick(el)}
              className={styles.image}
              src={img2Src}
              alt={""}
            />
          </div>
          <div className={styles.imageCont}>
            <img
              id={"img3"}
              onClick={(el) => handleClick(el)}
              className={styles.image}
              src={img3Src}
              alt={""}
            />
          </div>
        </div>
        <div className={styles.activeImageCont}>
          <img
            className={styles.image}
            src={activeImg}
            alt={""}
            id={"rental"}
          />
        </div>
      </div>
      <div>
        <ComponentSwiper img1={img1} img2={img2} img3={img3} img4={img4} />
      </div>

      <h2>{t("rental.header")}</h2>

      <div className={styles.description} style={{ textAlign: "justify" }}>
        {t("rental.description")}
      </div>
    </div>
  );
}
