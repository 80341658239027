import React, { useEffect, useState } from "react";
import styles from "./hero.module.css";
import { CircularProgress } from "@mui/material";
import { useVideo } from "react-use";
import { useTranslation } from "react-i18next";
//import vid from "../../assets/aug1.mp4";
let windowWidth = window.innerWidth;
export function Hero() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.addEventListener("resize", function () {
      windowWidth = window.innerWidth;
    });
    return () => {
      window.removeEventListener("resize", function () {
        windowWidth = window.innerWidth;
      });
    };
  }, []);

  const [video, state, controls, ref] = useVideo(
    <video
      className={styles.video}
      src={
        windowWidth > 640
          ? process.env.PUBLIC_URL + "/top_desc_new.mp4"
          : process.env.PUBLIC_URL + "/top_mob_new.mp4"
      }
      autoPlay
      loop
      muted
      playsInline
    />
  );
  useEffect(() => {
    if (state.playing) {
      setLoading(false);
    }
  }, [state]);
  return (
    <div className={styles.container}>
      <div className={styles.heroImage}>{video}</div>

      {loading && (
        <div className={styles.spinner}>
          <CircularProgress />
        </div>
      )}
      <div className={styles.heroDescription}>
        <img
          className={styles.img}
          src={require("../../assets/images/header_background.png")}
          alt={"layout"}
        />
        <div className={styles.descrContainer}>
          <h2 className={styles.header}>DEN HVIDE BY</h2>
          <div className={styles.villageBlock}>
            <div className={styles.divider}></div>
            <p className={styles.village}>THE WHITE VILLAGE</p>
            <div className={styles.divider}></div>
          </div>
          <h2 className={styles.header}>{t("hero")}</h2>
        </div>
        <div className={styles.videoCover}></div>
      </div>
    </div>
  );
}
