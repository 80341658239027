import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import styles from "./languageselect.module.css";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
//import ListSubheader from "@mui/material/ListSubheader";
import Icon from "../../assets/images/flag.png";
import Icon1 from "../../assets/images/flag1.png";
import Icon2 from "../../assets/images/flag2.png";
import { activeLang } from "../../App";

const LanguageSelect = () => {
  const languageMap = {
    en: {
      label: <img src={Icon} alt={"english language"} />,
      dir: "ltr",
      active: true,
      lan: "en",
    },
    de: {
      label: <img src={Icon1} alt={"deutsche sprache"} />,
      dir: "ltr",
      active: true,
      lan: "de",
    },
    da: {
      label: <img src={Icon2} alt={"dansk sprog"} />,
      dir: "ltr",
      active: true,
      lan: "da",
    },
  };

  let selected = localStorage.getItem("i18nextLng") || "en";

  if (selected.startsWith("de")) {
    selected = "de";
  } else if (selected.startsWith("en")) {
    selected = "en";
  } else if (selected.startsWith("da")) {
    selected = "da";
  } else {
    selected = "en";
  }

  useEffect(() => {
    i18next.changeLanguage(selected);
    activeLang(selected);
  }, []);

  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);

  return (
    <span>
      <Button
        sx={{
          transform: "translateY(-2px)",
          paddingBottom: 0,
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
          justifyContent: "right",
          minWidth: "31px !important",
        }}
        onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
      >
        {languageMap[selected].label}
        <div className={styles.arrow}>
          <ArrowDropDown fontSize="small" style={{ verticalAlign: "middle" }} />
        </div>
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ background: "linear-gradient(#ffffff, #868686)" }}>
          <List>
            {/*<ListSubheader style={{ background: " #868686" }}>
              {t("select_language")}
            </ListSubheader>*/}
            {Object.keys(languageMap)?.map(
              (item) =>
                languageMap[item].lan !== selected && (
                  <ListItem
                    button
                    key={item}
                    onClick={() => {
                      i18next.changeLanguage(item);
                      activeLang(item);
                      setMenuAnchor(null);
                    }}
                  >
                    {languageMap[item].label}
                  </ListItem>
                )
            )}
          </List>
        </div>
      </Popover>
    </span>
  );
};

export default LanguageSelect;
