import React, { useEffect, useState } from "react";
import styles from "./purposes.module.css";
import { ComponentSwiper } from "../ComponentSwiper";
import img1 from "../../../assets/images/image3-1.jpg";
import img2 from "../../../assets/images/image3-2.jpg";
import img3 from "../../../assets/images/image3-3.jpg";
import img4 from "../../../assets/images/image3-4.jpg";
import { useTranslation } from "react-i18next";
import { activePage } from "../../../App";

export function Purposes() {
  const { t } = useTranslation();
  const [activeImg, setActiveImg] = useState(img1);
  const [img1Src, setImg1Src] = useState(img4);
  const [img2Src, setImg2Src] = useState(img2);
  const [img3Src, setImg3Src] = useState(img3);
  useEffect(() => {
    activePage("purposes");
  }, []);

  const handleClick = (el) => {
    switch (el.currentTarget.id) {
      case "img1":
        setImg1Src(activeImg);
        setActiveImg(img1Src);
        break;
      case "img2":
        setImg2Src(activeImg);
        setActiveImg(img2Src);
        break;
      case "img3":
        setImg3Src(activeImg);
        setActiveImg(img3Src);
        break;

      default:
        setActiveImg(img4);
        setImg1Src(img1);
        setImg2Src(img2);
        setImg3Src(img3);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.galleryContainer}>
        <div className={styles.gallery}>
          <div className={styles.imageCont}>
            <img
              id={"img1"}
              onClick={(el) => handleClick(el)}
              className={styles.image}
              src={img1Src}
              alt={""}
            />
          </div>
          <div className={styles.imageCont}>
            <img
              id={"img2"}
              onClick={(el) => handleClick(el)}
              className={styles.image}
              src={img2Src}
              alt={""}
            />
          </div>
          <div className={styles.imageCont}>
            <img
              id={"img3"}
              onClick={(el) => handleClick(el)}
              className={styles.image}
              src={img3Src}
              alt={""}
            />
          </div>
        </div>
        <div className={styles.activeImageCont}>
          <img
            className={styles.image}
            id={"purposes"}
            src={activeImg}
            alt={""}
          />
        </div>
      </div>
      <div>
        <ComponentSwiper img1={img1} img2={img2} img3={img3} img4={img4} />
      </div>

      <h2>{t("purposes.header")}</h2>

      <div className={styles.description} style={{ textAlign: "justify" }}>
        {t("purposes.description")}
      </div>
      <div className={styles.description} style={{ textAlign: "justify" }}>
        {t("purposes.listheader")}
      </div>
      <ul className={styles.list}>
        <li>{t("purposes.item1")}</li>
        <li>{t("purposes.item2")}</li>
        <li>{t("purposes.item3")}</li>
        <li>{t("purposes.item4")}</li>
        <li>{t("purposes.item5")}</li>
        <li>{t("purposes.item6")}</li>
        <li>{t("purposes.item7")}</li>
        <li>{t("purposes.item8")}</li>
        <li>{t("purposes.item9")}</li>
        <li>{t("purposes.item10")}</li>
        <li>{t("purposes.item11")}</li>
      </ul>

      <div className={styles.description}>{t("purposes.description1")}</div>
    </div>
  );
}
