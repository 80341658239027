import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./complex.module.css";
import img1 from "../../../assets/images/image6.jpg";
import img2 from "../../../assets/images/image8.jpg";
import img4 from "../../../assets/images/image4.jpg";
import img3 from "../../../assets/images/image5.jpg";
import { ComponentSwiper } from "../ComponentSwiper";
import { activePage } from "../../../App";

export function Complex() {
  const { t } = useTranslation();
  const [activeImg, setActiveImg] = useState(img4);
  const [img1Src, setImg1Src] = useState(img1);
  const [img2Src, setImg2Src] = useState(img2);
  const [img3Src, setImg3Src] = useState(img3);

  useEffect(() => {
    activePage("complex");
  }, []);

  const handleClick = (el) => {
    switch (el.currentTarget.id) {
      case "img1":
        setImg1Src(activeImg);
        setActiveImg(img1Src);
        break;
      case "img2":
        setImg2Src(activeImg);
        setActiveImg(img2Src);
        break;
      case "img3":
        setImg3Src(activeImg);
        setActiveImg(img3Src);
        break;

      default:
        setActiveImg(img4);
        setImg1Src(img1);
        setImg2Src(img2);
        setImg3Src(img3);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.galleryContainer}>
        <div className={styles.gallery}>
          <div className={styles.imageCont}>
            <img
              id={"img1"}
              onClick={(el) => handleClick(el)}
              className={styles.image}
              src={img1Src}
              alt={""}
            />
          </div>
          <div className={styles.imageCont}>
            <img
              id={"img2"}
              onClick={(el) => handleClick(el)}
              className={styles.image}
              src={img2Src}
              alt={""}
            />
          </div>
          <div className={styles.imageCont}>
            <img
              id={"img3"}
              onClick={(el) => handleClick(el)}
              className={styles.image}
              src={img3Src}
              alt={""}
            />
          </div>
        </div>
        <div className={styles.activeImageCont}>
          <img
            className={styles.image}
            id={"complex"}
            src={activeImg}
            alt={""}
          />
        </div>
      </div>
      <div>
        <ComponentSwiper img1={img1} img2={img2} img3={img3} img4={img4} />
      </div>

      <h2>{t("complex.header")}</h2>
      <h3>{t("complex.header2")}</h3>
      <table className={styles.description}>
        <tbody>
          <tr className={styles.greyRow}>
            <td className={styles.leftCell} colSpan={1}>
              {t("complex.row1")}
            </td>
            <td className={styles.greyCell} colSpan={1}>
              7.968 m2
            </td>
          </tr>
          <tr>
            <td className={styles.leftCell} colSpan={1}>
              {t("complex.row2")}
            </td>
            <td className={styles.whiteCell} colSpan={1}>
              455 –
            </td>
          </tr>
          <tr className={styles.greyRow}>
            <td className={styles.leftCell} colSpan={1}>
              {t("complex.row3")}
            </td>
            <td className={styles.greyCell} colSpan={1}>
              151 –
            </td>
          </tr>
          <tr>
            <td className={styles.leftCell} colSpan={1}>
              {t("complex.row4")}
            </td>
            <td className={styles.whiteCell} colSpan={1}>
              154 –
            </td>
          </tr>
          <tr>
            <td className={styles.tableSmallDescription} colSpan={2}>
              {t("complex.row5")}
            </td>
          </tr>
          <tr className={styles.greyRow}>
            <td className={styles.leftCell} colSpan={1}>
              {t("complex.row6")}
            </td>
            <td className={styles.greyCell} colSpan={1}>
              32.700 m2
            </td>
          </tr>
        </tbody>
      </table>

      <div className={styles.description} style={{ textAlign: "justify" }}>
        {t("complex.description")}
      </div>
      <div className={styles.description}>{t("complex.description2")}</div>
    </div>
  );
}
