import React, { useEffect, useState } from "react";
import styles from "./mainmenu.module.css";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import LanguageSelect from "../../utils/language/LanguageSelect";
import Icon from "../../assets/images/house_red.png";
import classNames from "classnames";
import { Link, animateScroll as scroll, scroller } from "react-scroll";
import { Drawer } from "antd";

export function MainMenu() {
  const { t } = useTranslation();
  const activeStyle = {
    color: "#c6102e",
  };

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(!open);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener("resize", () => setOpen(false));
  }, []);

  const burgerClass = classNames(styles.burger, {
    [styles[`open`]]: open,
  });
  const burgerClassS = classNames(styles.burgerSpan, {
    [styles[`open`]]: open,
  });

  const Menu = () => {
    return (
      <div className={styles.menuContainer}>
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className={styles.link}
          to="/"
          onClick={() => {
            onClose();
            setTimeout(() => {
              scroller.scrollTo("home", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: 0,
              });
            }, 1);
          }}
        >
          {t("header.home_header")}
        </NavLink>
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className={styles.link}
          to="complex"
          onClick={() => {
            onClose();
            setTimeout(() => {
              scroller.scrollTo("complex", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: 0,
              });
            }, 10);
          }}
        >
          {t("header.complex_header")}
        </NavLink>
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className={styles.link}
          to="area"
          onClick={() => {
            onClose();
            setTimeout(() => {
              scroller.scrollTo("area", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: 0,
              });
            }, 10);
          }}
        >
          {t("header.area_header")}
        </NavLink>
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className={styles.link}
          to="purposes"
          onClick={() => {
            onClose();
            setTimeout(() => {
              scroller.scrollTo("purposes", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: 0,
              });
            }, 10);
          }}
        >
          {t("header.purposes_header")}
        </NavLink>
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className={styles.link}
          to="rental"
          onClick={() => {
            onClose();
            setTimeout(() => {
              scroller.scrollTo("rental", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: 0,
              });
            }, 10);
          }}
        >
          {t("header.rental_header")}
        </NavLink>
        {/*<NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className={styles.link}
          to="contact"
          onClick={onClose}
        >
          {t("header.contact_header")}
        </NavLink>*/}
      </div>
    );
  };

  /*const BtnTest = () => {
    return (
      <div className={styles.burgerBtn}>
        <button className={burgerClass} onClick={onClose}>
          <span className={burgerClassS}></span>
          <span className={styles.burgerSpan}></span>
          <span className={styles.burgerSpan}></span>
        </button>
      </div>
    );
  };*/

  return (
    <div className={styles.container}>
      <div className={styles.navWrap}>
        <nav className={styles.nav}>
          <div className={styles.innerContainer}>
            <div className={styles.mobileMenu}>
              <div>
                <div className={styles.burgerBtn}>
                  <button className={burgerClass} onClick={showDrawer}>
                    <span className={burgerClassS}></span>
                    <span className={styles.burgerSpan}></span>
                    <span className={styles.burgerSpan}></span>
                  </button>
                </div>

                <Drawer
                  className={styles.innerDrawer}
                  bodyStyle={{ display: "flex" }}
                  rootClassName={styles.drawer}
                  placement="left"
                  title={""}
                  closable={false}
                  mask={true}
                  onClose={onClose}
                  open={open}
                  key="left"
                >
                  <Menu />
                </Drawer>
              </div>
            </div>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              className={styles.mobileHomeLink}
              to="/"
              onClick={onClose}
            >
              <img className={styles.homeIconMob} src={Icon} alt="home" />
            </NavLink>
            <div className={styles.desktopMenu}>
              <Menu />
              {/*<NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className={styles.link}
                to="/"
              >
                <img className={styles.homeIcon} src={Icon} alt="home" />
                {t("header.home_header")}
              </NavLink>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className={styles.link}
                to="complex"
              >
                {t("header.complex_header")}
              </NavLink>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className={styles.link}
                to="area"
              >
                {t("header.area_header")}
              </NavLink>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className={styles.link}
                to="purposes"
              >
                {t("header.purposes_header")}
              </NavLink>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className={styles.link}
                to="rental"
              >
                {t("header.rental_header")}
              </NavLink>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className={styles.link}
                to="contact"
              >
                {t("header.contact_header")}
              </NavLink>*/}
            </div>
            {
              <div className={styles.languageSelector}>
                {<LanguageSelect />}
              </div>
            }
          </div>
        </nav>
      </div>
    </div>
  );
}
