import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import img1 from "../../../assets/images/image2-1.jpg";
import img2 from "../../../assets/images/image2-2.jpg";
import img4 from "../../../assets/images/image2-3.jpg";
import img3 from "../../../assets/images/image2-4.jpg";
import img5 from "../../../assets/images/image2-5.jpg";
import img6 from "../../../assets/images/image2-6.jpg";
import img7 from "../../../assets/images/image2-7.jpg";

/*import Taxi from "../../../assets/images/taxi-4-svgrepo-com.svg";
import Plane from "../../../assets/images/airplane-svgrepo-com.svg";
import Bus from "../../../assets/images/bus.svg";*/
import { ComponentSwiper } from "../ComponentSwiper";
import { activePage } from "../../../App";

import styles from "./area.module.css";

export function Area() {
  const { t } = useTranslation();
  const [activeImg, setActiveImg] = useState(img1);
  const [img1Src, setImg1Src] = useState(img4);
  const [img2Src, setImg2Src] = useState(img2);
  const [img3Src, setImg3Src] = useState(img3);
  const [img5Src, setImg5Src] = useState(img5);
  const [img6Src, setImg6Src] = useState(img6);
  const [img7Src, setImg7Src] = useState(img7);

  useEffect(() => {
    activePage("area");
  }, []);

  const handleClick = (el) => {
    switch (el.currentTarget.id) {
      case "img1":
        setImg1Src(activeImg);
        setActiveImg(img1Src);
        break;
      case "img2":
        setImg2Src(activeImg);
        setActiveImg(img2Src);
        break;
      case "img3":
        setImg3Src(activeImg);
        setActiveImg(img3Src);
        break;

      case "img5":
        setImg5Src(activeImg);
        setActiveImg(img5Src);
        break;
      case "img6":
        setImg6Src(activeImg);
        setActiveImg(img6Src);
        break;
      case "img7":
        setImg7Src(activeImg);
        setActiveImg(img7Src);
        break;
      default:
        setActiveImg(img4);
        setImg1Src(img1);
        setImg2Src(img2);
        setImg3Src(img3);
        setImg5Src(img5);
        setImg6Src(img6);
        setImg7Src(img7);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.galleryContainer}>
        <div className={styles.gallery}>
          <div className={styles.imageCont}>
            <img
              id={"img1"}
              onClick={(el) => handleClick(el)}
              className={styles.image}
              src={img1Src}
              alt={""}
            />
          </div>
          <div className={styles.imageCont}>
            <img
              id={"img2"}
              onClick={(el) => handleClick(el)}
              className={styles.image}
              src={img2Src}
              alt={""}
            />
          </div>
          <div className={styles.imageCont}>
            <img
              id={"img3"}
              onClick={(el) => handleClick(el)}
              className={styles.image}
              src={img3Src}
              alt={""}
            />
          </div>
        </div>
        <div className={styles.activeImageCont}>
          <img className={styles.image} id={"area"} src={activeImg} alt={""} />
        </div>
        <div className={styles.gallery}>
          <div className={styles.imageCont}>
            <img
              id={"img5"}
              onClick={(el) => handleClick(el)}
              className={styles.image}
              src={img5Src}
              alt={""}
            />
          </div>
          <div className={styles.imageCont}>
            <img
              id={"img6"}
              onClick={(el) => handleClick(el)}
              className={styles.image}
              src={img6Src}
              alt={""}
            />
          </div>
          <div className={styles.imageCont}>
            <img
              id={"img7"}
              onClick={(el) => handleClick(el)}
              className={styles.image}
              src={img7Src}
              alt={""}
            />
          </div>
        </div>
      </div>
      <div>
        <ComponentSwiper
          img1={img1}
          img2={img2}
          img3={img3}
          img4={img4}
          img5={img5}
          img6={img6}
          img7={img7}
          source={"area"}
        />
      </div>

      <h2>{t("area.header")}</h2>

      {/*<div className={styles.description} style={{ textAlign: "center" }}>
        {t("area.table")}
      </div>*/}

      {/*<table className={styles.description}>
        <thead>
          <tr>
            <th className={styles.tableHeader} scope="col">
              Sønderborg Lufthavn
            </th>
            <th className={styles.tableHeader} scope="col">
              Flensburg
            </th>
            <th className={styles.tableHeader} scope="col">
              Hamburg
            </th>
            <th className={styles.tableHeader} scope="col">
              Copenhagen
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className={styles.greyRow}>
            <td className={styles.cell}>
              <div className={styles.cellContent}>
                <div className={styles.cardLeftImage}>
                  <img
                    className={styles.smallIcon}
                    src={Taxi}
                    alt={"taxi icon"}
                  />
                </div>
                <div className={styles.cityDes}>
                  <p className={styles.cityName}>
                    Sønderborg
                    <img
                      className={styles.smallIcon}
                      src={Plane}
                      alt={"airplane icon"}
                    />
                  </p>
                  <p className={styles.cityName}>Augustenborg</p>
                </div>
                <div className={styles.time}>0h 11</div>
              </div>
            </td>
            <td className={styles.cell}>
              <div className={styles.cellContent}>
                <div className={styles.cardLeftImage}>
                  <img
                    className={styles.smallIcon}
                    src={Bus}
                    alt={"bus icon"}
                  />
                </div>
                <div className={styles.cityDes}>
                  <p className={styles.cityName}>Flensburg</p>
                  <p className={styles.cityName} style={{ fontWeight: 400 }}>
                    Sønderborg
                  </p>
                </div>
                <div className={styles.time}>1h 15</div>
              </div>
            </td>
            <td className={styles.cell}>
              <div className={styles.cellContent}>
                <div className={styles.cardLeftImage}>
                  <img
                    className={styles.smallIcon}
                    src={Bus}
                    alt={"bus icon"}
                  />
                </div>
                <div className={styles.cityDes}>
                  <p className={styles.cityName}>Hamburg</p>
                  <p className={styles.cityName} style={{ fontWeight: 400 }}>
                    Flensburg
                  </p>
                </div>
                <div className={styles.time}>1h 48</div>
              </div>
            </td>
            <td className={styles.cell}>
              <div className={styles.cellContent}>
                <div className={styles.cardLeftImage}>
                  <img
                    className={styles.smallIcon}
                    src={Bus}
                    alt={"bus icon"}
                  />
                </div>
                <div className={styles.cityDes}>
                  <p className={styles.cityName}>Copenhagen</p>
                  <p className={styles.cityName} style={{ fontWeight: 400 }}>
                    Fredericia
                  </p>
                </div>
                <div className={styles.time}>4h 30</div>
              </div>
            </td>
          </tr>
          <tr
            className={styles.greyRow}
            style={{ borderLeft: "none", borderBottom: "none" }}
          >
            <td className={styles.cell}></td>
            <td
              className={styles.cell}
              style={{ borderBottom: "1px solid #c6102e" }}
            >
              <div className={styles.cellContent}>
                <div className={styles.cardLeftImage}>
                  <img
                    className={styles.smallIcon}
                    src={Bus}
                    alt={"bus icon"}
                  />
                </div>
                <div className={styles.cityDes}>
                  <p className={styles.cityName} style={{ fontWeight: 400 }}>
                    Sønderborg
                  </p>
                  <p className={styles.cityName}>Augustenborg</p>
                </div>
                <div className={styles.time}>0h 14</div>
              </div>
            </td>
            <td
              className={styles.cell}
              style={{ borderBottom: "1px solid #c6102e" }}
            >
              <div className={styles.cellContent}>
                <div className={styles.cardLeftImage}>
                  <img
                    className={styles.smallIcon}
                    src={Bus}
                    alt={"bus icon"}
                  />
                </div>
                <div className={styles.cityDes}>
                  <p className={styles.cityName} style={{ fontWeight: 400 }}>
                    Flensburg
                  </p>
                  <p className={styles.cityName} style={{ fontWeight: 400 }}>
                    Sønderborg
                  </p>
                </div>
                <div className={styles.time}>1h 15</div>
              </div>
            </td>
            <td
              className={styles.cell}
              style={{ borderBottom: "1px solid #c6102e" }}
            >
              <div className={styles.cellContent}>
                <div className={styles.cardLeftImage}>
                  <img
                    className={styles.smallIcon}
                    src={Bus}
                    alt={"bus icon"}
                  />
                </div>
                <div className={styles.cityDes}>
                  <p className={styles.cityName} style={{ fontWeight: 400 }}>
                    Sønderborg
                  </p>
                  <p className={styles.cityName}>Augustenborg</p>
                </div>
                <div className={styles.time}>0h 14</div>
              </div>
            </td>
          </tr>
          <tr
            className={styles.greyRow}
            style={{
              borderLeft: "none",
              borderBottom: "none",
              borderRight: "none",
            }}
          >
            <td className={styles.cell} style={{ borderRight: "none" }}></td>
            <td className={styles.cell}></td>
            <td
              className={styles.cell}
              style={{ borderBottom: "1px solid #c6102e" }}
            >
              <div className={styles.cellContent}>
                <div className={styles.cardLeftImage}>
                  <img
                    className={styles.smallIcon}
                    src={Bus}
                    alt={"bus icon"}
                  />
                </div>
                <div className={styles.cityDes}>
                  <p className={styles.cityName} style={{ fontWeight: 400 }}>
                    Sønderborg
                  </p>
                  <p className={styles.cityName}>Augustenborg</p>
                </div>
                <div className={styles.time}>0h 14</div>
              </div>
            </td>
            <td className={styles.cell} style={{ borderBottom: "none" }}></td>
          </tr>
        </tbody>
      </table>*/}
      <ul>
        <li>
          <div className={styles.description} style={{ textAlign: "justify" }}>
            {t("area.description1")}
            <span className={styles.bold}>{t("area.description1_2")}</span>
            {t("area.description1_3")}
            <span className={styles.bold}>{t("area.description1_4")}</span>
            {t("area.description1_5")}
            <span className={styles.bold}>{t("area.description1_6")}</span>
          </div>
        </li>
        <li>
          <div className={styles.description} style={{ textAlign: "justify" }}>
            {t("area.description2")}
            <span className={styles.bold}> {t("area.description2_1")}</span>
            {t("area.description2_2")}
          </div>
        </li>
        <li>
          <div className={styles.description} style={{ textAlign: "justify" }}>
            {t("area.description3")}
            <span className={styles.bold}>{t("area.description3_1")}</span>
            {t("area.description3_2")}
          </div>
        </li>
        <li>
          <div className={styles.description} style={{ textAlign: "justify" }}>
            {t("area.description4")}
            <span className={styles.bold}>{t("area.description4_1")}</span>
            {t("area.description4_2")}
            <span className={styles.bold}>{t("area.description4_3")}</span>
            {t("area.description4_4")}
            <span className={styles.bold}>{t("area.description4_5")}</span>
            {t("area.description4_6")}
            <span className={styles.bold}>{t("area.description4_7")}</span>
            {t("area.description4_8")}
          </div>
        </li>
      </ul>
    </div>
  );
}
