import React, { useEffect, useState } from "react";
import styles from "./home.module.css";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import icon1 from "../../../assets/images/airport.png";
import icon2 from "../../../assets/images/pngwing.png";
import icon3 from "../../../assets/images/border.png";
import icon4 from "../../../assets/images/road.png";
import icon5 from "../../../assets/images/logo.png";
import icon6 from "../../../assets/images/golf.png";
import icon7 from "../../../assets/images/multiple.png";
import icon8 from "../../../assets/images/river.png";
import { useStore } from "effector-react";
import { $currentLang } from "../../../App";
import { activePage } from "../../../App";
import { useVideo } from "react-use";
import playButton from "../../../assets/images/play.svg";

import logoDa from "../../../assets/images/logo_da.png";

let windowWidth = window.innerWidth;

export function Home() {
  const curLang = useStore($currentLang);
  const [playV, setPlayV] = useState(false);
  const [iconLogo, setIconLogo] = useState(curLang === "da" ? logoDa : icon5);
  //const [initial, setInitial] = useState(true);

  useEffect(() => {
    window.addEventListener("resize", function () {
      windowWidth = window.innerWidth;
    });
    return () => {
      window.removeEventListener("resize", function () {
        windowWidth = window.innerWidth;
      });
    };
  }, []);

  const { t } = useTranslation();
  const [largeVideo, state, controls] = useVideo(
    <video
      className={styles.video}
      style={{}}
      src={
        windowWidth > 640
          ? process.env.PUBLIC_URL + "/bottom_desc_new.mp4#t=0.001"
          : process.env.PUBLIC_URL + "/bottom_mob_new.mp4#t=0.001"
      }
      loop
      preload={"auto"}
      playsInline
    />
  );
  /*useEffect(() => {
    if (windowWidth < 640) {
      controls.mute();
      controls.play();
      controls.pause();
    }
  }, []);

  useEffect(() => {
    if (initial && windowWidth < 640) {
      if (state.time > 0) {
        controls.pause();
        controls.seek(0);
        controls.unmute();
        setInitial(false);
      }
    }
  }, [state]);*/

  useEffect(() => {
    setIconLogo(curLang === "da" ? logoDa : icon5);
  }, [curLang]);

  useEffect(() => {
    if (playV) {
      controls.play();
    } else {
      controls.pause();
    }
  }, [playV]);

  const videoClass = classNames(styles.pl, {
    [styles.videoPaused]: state.paused,
    [styles.player]: state.playing,
  });

  const playButtonClass = classNames(styles.playButton, {
    [styles.playButtonPause]: state.paused,
  });
  const icon7Class = classNames(styles.iconImg, styles.icon7);
  const wrap1 = classNames(styles.iconWrap, styles.icon1Wrap);
  const wrap3 = classNames(styles.iconWrap, styles.icon3Wrap);
  const wrap4 = classNames(styles.iconWrap, styles.icon4Wrap);
  const wrap6 = classNames(styles.iconWrap, styles.icon6Wrap);
  const wrap7 = classNames(styles.iconWrap, styles.icon7Wrap);
  const wrap9 = classNames(styles.iconWrap, styles.icon9Wrap);

  useEffect(() => {
    activePage("home");
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.contentSection}>
        <div className={styles.topDescription} id={"home"}>
          {t("central.top_description")}
        </div>
        <div className={styles.topDescription}>
          {t("central.top_description2")}
        </div>
        <div className={styles.circleContainer}>
          <div className={styles.deg1}>
            <div className={wrap1}>
              <div>
                <p
                  className={styles.iconDescription}
                  style={{ paddingRight: 10 }}
                >
                  {t("central.b1_1")}
                </p>
                <p className={styles.iconDescription}>{t("central.b1_2")}</p>
              </div>
              <div className={styles.icon}>
                <img
                  className={styles.iconImg}
                  src={icon1}
                  alt="airport icon"
                />
              </div>
            </div>
          </div>
          <div className={styles.deg2}>
            <div className={styles.iconWrap}>
              <p className={styles.iconDescription}>{t("central.b2_1")}</p>
              <p className={styles.iconDescription}>{t("central.b2_2")}</p>
              <div className={styles.icon}>
                <img
                  className={styles.iconImg}
                  src={icon2}
                  alt="historic icon"
                />
              </div>
            </div>
          </div>
          <div className={styles.deg3}>
            <div className={wrap3}>
              <div className={styles.icon}>
                <img className={styles.iconImg} src={icon3} alt="border icon" />
              </div>
              <div>
                <p className={styles.iconDescription}>{t("central.b3_1")}</p>
                <p
                  className={styles.iconDescription}
                  style={{ paddingLeft: 10 }}
                >
                  {t("central.b3_2")}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.deg4}>
            <div className={wrap4}>
              <div>
                <p
                  className={styles.iconDescription}
                  style={{ paddingRight: 10 }}
                >
                  {t("central.b4_1")}
                </p>
                <p className={styles.iconDescription}>{t("central.b4_2")}</p>
              </div>
              <div className={styles.icon}>
                <img className={styles.iconImg} src={icon4} alt="road icon" />
              </div>
            </div>
          </div>
          <div className={styles.deg5}>
            <div className={styles.iconWrap}>
              <div className={styles.centerIcon}>
                <img
                  className={styles.centerIconImg}
                  //src={icon5}
                  src={iconLogo}
                  alt="company logo"
                />
              </div>
            </div>
          </div>

          <div className={styles.deg6}>
            <div className={wrap6}>
              <div className={styles.icon}>
                <img className={styles.iconImg} src={icon6} alt="golf icon" />
              </div>
              <div>
                <p
                  className={styles.iconDescription}
                  style={{ paddingLeft: 10 }}
                >
                  {t("central.b6_1")}
                </p>
                <p
                  className={styles.iconDescription}
                  style={{ paddingLeft: 10 }}
                >
                  {t("central.b6_2")}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.deg7}>
            <div className={wrap7}>
              <div>
                <p
                  className={styles.iconDescription}
                  style={{ paddingRight: 10 }}
                >
                  {t("central.b7_1")}
                </p>
                <p className={styles.iconDescription}>{t("central.b7_2")}</p>
              </div>
              <div className={styles.icon}>
                <img
                  className={icon7Class}
                  src={icon7}
                  alt="multiple purpose icon"
                />
              </div>
            </div>
          </div>
          <div className={styles.deg8}>
            <div className={styles.iconWrap}>
              <div className={styles.icon}>
                <img className={styles.iconImg} src={icon8} alt="forest icon" />
              </div>
              <div>
                <p
                  className={styles.iconDescription}
                  style={{ paddingTop: window.innerWidth <= 320 ? 0 : 10 }}
                >
                  {t("central.b8_1")}
                </p>
                <p className={styles.iconDescription}>{t("central.b8_2")}</p>
              </div>
            </div>
          </div>
          <div className={styles.deg9}>
            <div className={wrap9}>
              <div className={styles.icon}>
                <span className={styles.sqm}>
                  m<sup className={styles.square}>2</sup>
                </span>
              </div>
              <div>
                <p className={styles.iconDescription}>{t("central.b9_1")}</p>
                <p
                  className={styles.iconDescription}
                  style={{ paddingLeft: 10 }}
                >
                  {t("central.b9_2")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.videoContainer}>
          <div
            className={videoClass}
            onClick={() => {
              setPlayV(!playV);
            }}
          >
            <img
              className={playButtonClass}
              src={playButton}
              alt={"play button"}
            />

            {largeVideo}
          </div>
        </div>
      </div>
    </div>
  );
}
