import React from "react";
import styles from "./nomatch.module.css";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../assets/404.jpg";

export function NoMatch() {
  const { t } = useTranslation();
  return (
    <div>
      <Container maxWidth={false}>
        <Link className={styles.errorLink} to="/">
          <div className={styles.errorMessage}>{t("404")}</div>
          <img className={styles.img} src={logo} alt={"not found"} />
          <p>{t("404-1")}</p>
        </Link>
      </Container>
    </div>
  );
}
