import React, { useEffect } from "react";
import styles from "./App.module.css";
import { MainMenu } from "./components/MainMenu";
import { Footer } from "./components/Footer";
import { SwiperComponent } from "./components/SwiperComponent";
import { Hero } from "./components/Hero";
import { Route, Routes } from "react-router-dom";
import { Home } from "./components/MainMenu/Home";
import { NoMatch } from "./components/NoMatch";
import { Complex } from "./components/MainMenu/Complex";
import { Area } from "./components/MainMenu/Area";
import { Purposes } from "./components/MainMenu/Purposes";
import { Rental } from "./components/MainMenu/Rental";
import { Contact } from "./components/MainMenu/Contact";
import { createStore, createEvent } from "effector";

let selected = localStorage.getItem("i18nextLng") || "en";

if (selected.startsWith("de")) {
  selected = "de";
} else if (selected.startsWith("en")) {
  selected = "en";
} else if (selected.startsWith("da")) {
  selected = "da";
} else {
  selected = "en";
}

export const activePage = createEvent("activePage");
export const activeLang = createEvent("activeLang");
export const homePage = createEvent();
export const $currentLang = createStore(selected).on(
  activeLang,
  (lang, newLang) => {
    return newLang;
  }
);
export const $currentPage = createStore("home")
  .on(activePage, (page, newPage) => {
    return newPage;
  })

  .reset(homePage);
function App() {
  //console.log = console.warn = console.error = () => {};
  return (
    <div className={styles.App}>
      <MainMenu />
      <div className={styles.hero}>
        <Hero />
      </div>
      <div className={styles.swiperDivider}>
        <Routes>
          <Route path="/" element={<MainMenu />} />
          <Route index element={<Home />} />
          <Route path="complex" element={<Complex />} />
          <Route path="area" element={<Area />} />
          <Route path="purposes" element={<Purposes />} />
          <Route path="rental" element={<Rental />} />
          {/*<Route path="contact" element={<Contact />} />*/}
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </div>
      <SwiperComponent />
      <Footer />
    </div>
  );
}

export default App;
